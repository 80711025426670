<template>
    <div>
        <div class="ht-table-wrapper">
            <div class="ht-table-header">
                <ResourceBarFilters @on-select-filter="$emit('on-select-filter')">
                    <template #title>
                        <div>
                            <h1 class="title">
                                {{ title }}
                            </h1>
                        </div>
                    </template>
                    <template
                        #button
                    >
                        <HtButton
                            v-if="$canCreate(permission, {id: -1})"
                            cypress="button-action-add"
                            @click.native="onCreate"
                        >
                            <t class="hide-mobile">
                                Add
                            </t>
                            <span class="hide-desktop">
                                <FontAwesomeIcon
                                    :icon="['fal', 'plus']"
                                    class="plus"
                                />
                            </span>
                        </HtButton>

                        <HtButton
                            v-if="canImport"
                            cypress="button-action-import"
                            @click.native="$refs.fileInput.click()"
                        >
                            <input
                                ref="fileInput"
                                type="file"
                                style="display: none;"
                                accept=".json"
                                @change="onFileImport"
                            >
                            <t class="hide-mobile">
                                Import
                            </t>
                            <span class="hide-desktop">
                                <FontAwesomeIcon
                                    :icon="['fal', 'plus']"
                                    class="plus"
                                />
                            </span>
                        </HtButton>
                        <HtButton
                            v-if="canExport"
                            cypress="button-action-import"
                            @click.native="onExport"
                        >
                            <t class="hide-mobile">
                                Export
                            </t>
                            <span class="hide-desktop">
                                <FontAwesomeIcon
                                    :icon="['fal', 'plus']"
                                    class="plus"
                                />
                            </span>
                        </HtButton>
                    </template>
                    <template #topFilter>
                        <HtSearchField
                            id="search"
                            v-model="search"
                            name="search"
                            class="search"
                        />
                    </template>
                </ResourceBarFilters>
            </div>
            <div class="ht-table-paginated">
                <div class="table-summary">
                    <div class="table-summary-results">
                        <Skeleton
                            v-if="isLoading"
                            width="120px"
                            :loading="true"
                        />
                        <t
                            v-else
                            :count="items.length"
                        >
                            {count} result | {count} results
                        </t>
                    </div>
                </div>

                <HtTableExtended
                    :items="allItems"
                    :columns="allColumns"
                    cypress="action-resource-table"
                    :empty-message="emptyMessage"
                    :external-search="search"
                    :has-quick-actions="hasQuickActions"
                    :has-checkboxes="canHaveCheckboxes"
                    front-filter
                    front-sort
                    @on-row-clicked="onRowClicked"
                    @on-action-clicked="onActionClicked"
                    @on-toggle-checkbox="onToggleCheckbox"
                    @on-toggle-all-checkbox="onCheckAll"
                >
                    <template #cell(resource.name)="scope">
                        <TableCellTextEllipsis
                            :text="scope.value"
                        />
                    </template>

                    <template #cell(participants)="scope">
                        <TableCellParticipants
                            v-if="scope.item.resource.programs[0] && scope.item.resource.programs[0].participants.length > 0"
                            :participants="scope.item.resource.programs[0].participants"
                        />
                        <span v-else>-</span>
                    </template>

                    <template #cell(program_name)="scope">
                        {{ scope.item.resource.programs[0]?.program?.name ?? '-' }}
                    </template>

                    <template #cell(filters)="scope">
                        <TableCellFilters
                            v-if="scope.item.resource.programs[0]"
                            :filters="scope.item.resource.programs[0].filters"
                        />
                        <span v-else>-</span>
                    </template>

                    <template #cell(status)="scope">
                        <HtTag :color="scope.item.status_color">
                            {{ scope.item.status_label }}
                        </HtTag>
                    </template>

                    <template #cell(is_locked)="scope">
                        <FontAwesomeIcon
                            v-if="scope.item.is_locked"
                            icon="lock"
                        />
                        <span v-else />
                    </template>

                    <template
                        v-for="(_, slot) in $slots"
                        #[slot]
                    >
                        <slot :name="slot" />
                    </template>

                    <template
                        v-for="(_, slot) in $scopedSlots"
                        #[slot]="slotData"
                    >
                        <slot
                            :name="slot"
                            v-bind="slotData || {}"
                        />
                    </template>
                </HtTableExtended>
            </div>
        </div>

        <Modalable
            v-if="isModalable"
            ref="modalable"
            class="modalable-1 medium"
            :mode="2"
            @onClose="$emit('on-close-modalable')"
        >
            <slot
                name="modalable"
                :selected-resource="selectedResource"
                :selected-resource-id="selectedResource ? selectedResource.id : null"
            />
        </Modalable>
    </div>
</template>

<script>
import ResourceBarFilters from '@/components/resources/ResourceBarFilters.vue';
import Modalable from '@/components/globals/Modalable.vue';
import HtTableExtended from '@/components/globals/HtTable/HtTableExtended.vue';
import I18n from '@/modules/i18n/I18n';
import TableCellFilters from '@/components/globals/table/TableCellFilters.vue';
import TableCellParticipants from '@/components/globals/table/TableCellParticipants.vue';
import TableCellTextEllipsis from '@/components/globals/table/TableCellTextEllipsis.vue';
import { Skeleton } from 'vue-loading-skeleton';
import HtButton from '@/components/globals/HtButton.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';

export default {
    name: 'ResourceListing',

    components: {
        TableCellTextEllipsis,
        TableCellParticipants,
        TableCellFilters,
        HtTableExtended,
        Modalable,
        ResourceBarFilters,
        Skeleton,
        HtButton,
        HtSearchField,
    },

    props: {
        title: {
            type: String,
            default: I18n.translate('Create'),
        },
        items: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
        permission: {
            type: String,
            required: true,
        },
        permissionTypeOnRowClicked: {
            type: String,
            default: 'update',
        },
        emptyMessage: {
            type: String,
            default: 'There are no resource to display yet...',
        },
        isModalable: {
            type: Boolean,
            default: true,
        },
        hasQuickActions: {
            type: Boolean,
            default: false,
        },
        hasCheckboxes: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            search: null,
            selectedResource: null,
            internalItems: [],
            startColumns: [
                {
                    label: this.translate('Name'),
                    key: 'resource.name',
                    class: 'font-weight-bold',
                    search: true,
                    style: {
                        'max-width': '256px',
                    },
                },
                {
                    label: this.translate('Participants'),
                    key: 'participants',
                    sortable: false,
                    search: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'program_name',
                    sortable: false,
                    search: false,
                },
                {
                    label: this.translate('Filters'),
                    key: 'filters',
                    sortable: false,
                    search: false,
                },
            ],
            endColumns: [
                {
                    label: this.translate('Locked'),
                    key: 'is_locked',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },

    computed: {
        allItems: {
            get() {
                return this.internalItems;
            },
            set(newItems) {
                this.internalItems = newItems;
            },
        },
        allColumns() {
            return [...this.startColumns, ...this.columns, ...this.endColumns];
        },
        isLoading() {
            return this.$store.state.resources.isLoading;
        },
        hasItems() {
            return this.items.length > 0;
        },
        isAutoConnectAdmin() {
            return this.$Auth.isAutoConnectAdmin();
        },
        canImport() {
            return this.isAutoConnectAdmin && this.hasCheckboxes;
        },
        canExport() {
            return this.isAutoConnectAdmin && this.hasCheckboxes;
        },
        canHaveCheckboxes() {
            return this.canImport || this.canExport;
        },
    },
    watch: {
        items: {
            handler(newItems) {
                this.internalItems = newItems.map((newItem) => ({
                    ...newItem,
                    is_checked: false,
                }));
            },
            immediate: true,
        },
    },
    methods: {
        setSearchValue(val) {
            this.search = val;
        },

        onRowClicked(row) {
            const hasPermission = this.permission && (row.item.permissions[`can_${this.permissionTypeOnRowClicked}`] ?? row.item.permissions[this.permissionTypeOnRowClicked]);

            if (!this.permission || hasPermission) {
                if (this.isModalable) {
                    this.selectedResource = row.item;
                    this.$refs.modalable.open();
                } else {
                    this.$emit('on-row-clicked', row);
                }
            }
        },

        onActionClicked(params) {
            this.$emit('on-action-clicked', params);
        },

        onCreate() {
            if (this.isModalable) {
                this.selectedResource = null;
                this.$refs.modalable.open();
            } else {
                this.$emit('on-create');
            }
        },
        onToggleCheckbox(toggleItem) {
            this.allItems = this.allItems.map((item) => {
                if (item.id === toggleItem.id) {
                    return { ...item, is_checked: !toggleItem.is_checked };
                }
                return item;
            });
        },
        onCheckAll(isAllChecked) {
            this.allItems = this.allItems.map((item) => ({ ...item, is_checked: isAllChecked }));
        },
        onExport() {
            const allChecked = this.allItems.filter((item) => item.is_checked);

            if (allChecked.length === 0) {
                this.$Notifier('App').showWarning(this.translate('Please check at least one item to process export.'));
                return;
            }

            this.$emit('on-export', allChecked);
        },
        onFileImport(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const jsonData = JSON.parse(e.target.result);
                        this.$emit('on-import', jsonData);
                    } catch (error) {
                        this.$Notifier('App').showError(this.translate('An error occured when reading json file'));
                    }
                };
                reader.readAsText(file);
                this.$refs.fileInput.value = null;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
/* TO REMOVE ONCE TABLE IS PAGINATED AND USES HtTablePagiated */
.ht-table-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ht-table-header {
    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 4px;
      align-self: flex-start;
    }
  }

  .ht-table-paginated {
    background-color: $neutral-white;
    font-size: 14px;
    color: $neutral-800;
    border: 1px solid $neutral-300;
    border-radius: 8px;

    .table-summary {
      font-weight: 600;
      color: $neutral-black;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
@media (max-width: $tablet) {
  .hide-mobile {
    display: none;
  }
}
@media (min-width: $tablet) {
  .hide-desktop {
    display: none;
  }
}
</style>
