<template>
    <ResourceListing
        :title="title"
        :items="formattedTrainings"
        :columns="columns"
        permission="ModelCompanyDocument"
        empty-message="There are no trainings to display yet..."
        has-quick-actions
        @on-select-filter="fetchData"
        @on-close-modalable="fetchData"
        @on-action-clicked="onActionClicked"
    >
        <template #cell(type)="scope">
            <div class="column-title">
                {{ translate($Utils.capitalize(scope.item.type)) }}
            </div>
        </template>

        <template #modalable="{ selectedResourceId }">
            <TrainingEdit
                :id="selectedResourceId"
                ref="trainingEdit"
                :disabled-program-list="false"
                :trainings="trainings"
                @onClose="fetchData"
                @onSave="fetchData"
                @onDelete="fetchData"
            />
        </template>
    </ResourceListing>
</template>

<script>
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import TrainingEdit from '@/components/resources/training/TrainingEdit.vue';
import api from '@/store/api';
import QuizEdit from '@/router/pages/resources/quizzes/QuizEdit.vue';

export default {
    permissions: [
        'ModelCompanyDocument',
    ],

    components: {
        QuizEdit,
        ResourceListing,
        TrainingEdit,
    },

    data() {
        return {
            trainings: [],
            columns: [
                {
                    label: this.translate('Type'),
                    key: 'type',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },

    computed: {
        title() {
            if (this.trainings.length === 0) {
                return this.translate('No training');
            }

            return this.translate('{count} training | {count} trainings', {
                count: this.trainings.length,
            });
        },
        formattedTrainings() {
            return this.trainings.map((training) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanyDocument', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...training,
                    quick_actions: quickActions,
                };
            });
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        replicateTraining(training) {
            api.configuration.trainings.replicate(training.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: training.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: training.resource.name,
                    }));
                });
        },
        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateTraining(item);
                break;
            }
        },
        fetchData() {
            this.$store.dispatch('resources/setLoading', true);

            api.configuration
                .trainings
                .getAll({
                    filters: this.$store.state.resources.filters,
                })
                .then((res) => {
                    this.trainings = res.data.data;

                    this.$store.dispatch('resources/setLoading', false);
                });
        },
    },
};
</script>
