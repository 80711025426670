<template>
    <div class="mb-5">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <HtH2 class="mb-0">
                <t>Requirements</t>
            </HtH2>
            <Button
                v-if="canCreate"
                class="negative"
                @click="openCreateModal()"
            >
                <t>Add</t>
            </Button>
        </div>

        <div>
            <HtList
                v-for="(userCategory, index) in companyUserRequirementCategoryArray"
                :key="userCategory.id"
                :index="index"
                :items="companyUserRequirementGroupedByCategory[userCategory.id]"
                @hover-row="turnHoverEdit"
            >
                <template #header-title>
                    <div class="d-flex justify-content-center">
                        <div>
                            <span :class="{'not-concerned': userCategory.progress_status === 'not_concerned'}">
                                {{ getTranslatedName(userCategory) }}
                            </span>
                        </div>
                        <div
                            v-if="userCategory.display_survey && userCategory.progress_status === 'pending'"
                            class="ml-4 header-title-label"
                        >
                            <t>Pending</t>
                        </div>
                        <div
                            v-else-if="userCategory.display_survey && userCategory.progress_status !== 'done'"
                            class="ml-4 header-title-label"
                        >
                            <t>Via survey</t>
                        </div>
                    </div>
                </template>
                <template #header-subtitle>
                    <span>{{ getTranslatedDescription(userCategory) }}</span>
                </template>
                <template #header-right>
                    <div class="d-flex align-items-center">
                        <div
                            v-if="userCategory.progress_status === 'not_concerned'"
                            class="not-concerned-wrapper mr-3"
                        >
                            <t>Not concerned</t>
                        </div>
                        <div
                            v-if="canEdit(userCategory) && !isArchives(userCategory)"
                            class="d-flex justify-content-between align-items-center edit-wrapper branding-color branding-color-darken-hover"
                            @click="openEditModal(index)"
                        >
                            <FontAwesomeIcon
                                icon="pencil-alt"
                                size="2x"
                                transform="shrink-10"
                            />
                            <t>Edit</t>
                        </div>
                        <div
                            v-if="hasArchives(userCategory)"
                            class="d-flex justify-content-between align-items-center edit-wrapper branding-color branding-color-darken-hover"
                            @click="openShowArchivesModal(index)"
                        >
                            <FontAwesomeIcon
                                :icon="['far', 'eye']"
                                size="2x"
                                transform="shrink-10"
                            />
                            <t>See archives</t>
                        </div>
                    </div>
                </template>
                <template #items-left="scope">
                    <span class="user-requirement-profile-item-header">{{ getTranslatedName(scope.item) }}</span>
                </template>
                <template #items-right="scope">
                    <UserRequirementRead
                        :index="index"
                        :is-participant="true"
                        :user-requirement="scope.item"
                        :user-id="companyUser.id"
                    />
                </template>
            </HtList>
        </div>

        <div
            v-if="companyUserRequirementCategoryArray.length > 0"
            class="mt-4 text-center"
        >
            <Button
                v-if="isAllowedToDownload"
                :state="buttonState"
                @click="onExport"
            >
                <t>Download ZIP</t>
            </Button>
            <Button
                v-if="canSendDms"
                class="negative ml-3"
                @click="$refs.userRequirementDmsModal.open(companyUser.id)"
            >
                <t>DMS</t>
            </Button>
            <Button
                v-if="canTriggerRequirementTag"
                class="negative ml-3"
                @click="$refs.userRequirementByTagsModal.open(companyUser.id)"
            >
                <t>Send data</t>
            </Button>
        </div>

        <div
            v-if="companyUserRequirementCategoryArray.length === 0"
            class="empty-component"
        >
            <b>
                <t>No requirements needed or not enough permission rights</t>
            </b>
        </div>

        <Modalable
            ref="modalableCreate"
            :mode="2"
            class="modalable-1 medium"
        >
            <HtFormSelect
                v-if="getUserPrograms.length > 1"
                :id="'choose-program'"
                v-model="chosenUserProgramId"
                :label="translate('Choose a program')"
                :name="'choose-program'"
                :options="getUserPrograms"
                :show-optional="false"
                @input="reloadCollection"
            />

            <UserRequirementCategoryCreateModal
                v-if="getUserProgramId"
                :key="getUserProgramId"
                :company-user-id="companyUser.id"
                :company-user-program-id="getUserProgramId"
                @on-create="reloadCollection"
            />
        </Modalable>

        <UserRequirementCategoryEditModal
            v-if="currentUserRequirementCategory"
            ref="modalEdit"
            @on-save="reloadCollection"
            @on-delete="reloadCollection"
        />

        <UserRequirementCategoryShowArchivesModal
            v-if="currentUserRequirementCategory"
            ref="modalShowArchives"
        />

        <UserRequirementDmsModal ref="userRequirementDmsModal" />
        <UserRequirementByTagsModal ref="userRequirementByTagsModal" />
    </div>
</template>

<script>
import UserRequirementEdit from '@/components/pages/onboarding/UserRequirementEdit.vue';
import UserRequirementCategoryEditModal from '@/components/pages/onboarding/UserRequirementCategoryEditModal.vue';
import UserRequirementCategoryShowArchivesModal from '@/components/pages/onboarding/UserRequirementCategoryShowArchivesModal.vue';
import UserRequirementCategoryCreateModal from '@/components/pages/onboarding/UserRequirementCategoryCreateModal.vue';
import UserRequirementDmsModal from '@/components/pages/dashboard/modals/requirement/UserRequirementDmsModal.vue';
import CompanyUserRequirement from '@/models/CompanyUserRequirement';
import HtH2 from '@/components/design-system/typography/header/HtH2.vue';
import UserRequirementRead from '@/components/pages/onboarding/UserRequirementRead.vue';
import UserRequirementByTagsModal from '@/components/pages/dashboard/modals/requirement/UserRequirementByTagsModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import CompanyUser from '@/models/CompanyUser';
import HtSelectSimple from '@/components/globals/HtSelectSimple.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import DownloadUploadFileMixin from '@/components/mixins/DownloadUploadFileMixin';

export default {
    name: 'UserRequirementProfile',
    components: {
        HtFormSelect,
        HtSelectSimple,
        UserRequirementRead,
        UserRequirementCategoryCreateModal,
        UserRequirementCategoryEditModal,
        UserRequirementCategoryShowArchivesModal,
        UserRequirementDmsModal,
        UserRequirementByTagsModal,
        UserRequirementEdit,
        HtH2,
    },
    mixins: [
        DownloadUploadFileMixin,
    ],
    props: {
        companyUser: {
            type: CompanyUser,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    permissions: [
        'ModelCompanyUserRequirementCategory',
        'AbstractDocumentManagementSystem',
    ],

    data() {
        return {
            showListIcons: {},
            buttonState: 'idle',
            currentUserRequirementCategory: null,
            companyUserRequirementCategoryArray: [],
            chosenUserProgramId: null,
        };
    },

    computed: {
        getUserPrograms() {
            return this.companyUser.company_user_program.models
                .filter((userProgram) => userProgram.status === CompanyUserProgram.STATUS_ACTIVE)
                .map((userProgram) => ({
                    id: userProgram.id,
                    name: this.oldLocalize(userProgram.company_program.locales.models, 'name'),
                }));
        },
        getUserProgramId() {
            if (this.getUserPrograms.length === 1) {
                return this.getUserPrograms[0].id;
            }
            return this.chosenUserProgramId;
        },
        hasPermission() {
            return this.$canRead('AbstractDocumentManagementSystem');
        },

        canSendDms() {
            return this.hasPermission
                && this.shared.session.company.hasDms;
        },

        canTriggerRequirementTag() {
            return this.hasPermission
                && (this.$store.state.config.settings.triggers_requirements_tags ?? false);
        },

        companyUserRequirementGroupedByCategory() {
            const oCompanyUserRequirementByCategory = {};

            this.companyUserRequirementCategoryArray.forEach((category) => {
                const aCompanyUserRequirement = [];
                category.company_user_requirement_group.forEach((group) => {
                    group.company_user_requirement.forEach((requirement) => {
                        aCompanyUserRequirement.push(requirement);
                    });
                });
                oCompanyUserRequirementByCategory[category.id] = aCompanyUserRequirement;
            });

            return oCompanyUserRequirementByCategory;
        },

        canCreate() {
            return this.$canCreate('ModelCompanyUserRequirementCategory', {
                company_user_id: this.companyUser.id,
            }) && this.companyUserProgramId;
        },

        isAllowedToDownload() {
            return this.shared.session.can_onboardee_download_requirements;
        },
    },

    created() {
        this.reloadCollection();
    },

    methods: {
        canEdit(userCategory) {
            return !userCategory.display_survey || userCategory?.userSurvey.task.status === 'done';
        },
        isArchives(userCategory) {
            return userCategory.status === 'archived' && userCategory.archived_at !== null;
        },
        hasArchives(userCategory) {
            return userCategory.archives_count > 0;
        },
        openEditModal(index) {
            this.currentUserRequirementCategory = this.companyUserRequirementCategoryArray[index];
            this.$nextTick(() => this.$refs.modalEdit.open(
                this.companyUser.id,
                this.currentUserRequirementCategory.id,
                this.getExecutorTaskFromRequirementCategory(this.currentUserRequirementCategory),
            ));
        },

        openShowArchivesModal(index) {
            this.currentUserRequirementCategory = this.companyUserRequirementCategoryArray[index];
            this.$nextTick(() => this.$refs.modalShowArchives.open(
                this.companyUser.id,
                this.currentUserRequirementCategory.id,
            ));
        },

        openCreateModal() {
            this.$refs.modalableCreate.setTitle(this.translate('Add requirements'));
            this.$refs.modalableCreate.open();
        },

        async onExport() {
            this.buttonState = 'loading';
            await this.downloadUserRequirementFiles(this.companyUser.id, true);
            this.buttonState = 'idle';
        },

        turnHoverEdit(hovering, index) {
            const requirement = this.companyUserRequirementCategoryArray[index];

            if (requirement && (requirement.status === CompanyUserRequirement.STATUS_DONE || requirement.display_survey === false)) {
                this.showListIcons[requirement.id] = hovering;
            }
        },

        reloadCollection() {
            this.$http.get(`enrollee/${this.companyUser.id}/requirements/profil`).then((response) => {
                this.companyUserRequirementCategoryArray = response.data.data;
                this.companyUserRequirementCategoryArray.forEach((userRequirement) => {
                    this.$set(this.showListIcons, userRequirement.id, false);
                });
            });
        },

        getExecutorTaskFromRequirementCategory(userRequirementCategory) {
            return userRequirementCategory.tasks.find(
                (t) => t.status === CompanyUserProgramTask.STATUS_PENDING && t.participants.find(
                    (p) => p.company_user_id === this.shared.session.companyUser.id,
                ),
            );
        },

        getTranslatedName(item) {
            if (item.is_heyteam) {
                return this.translate(item.name);
            }
            return item.name;
        },

        getTranslatedDescription(item) {
            if (item.is_heyteam) {
                return this.translate(item.description);
            }
            return item.description;
        },
    },
};
</script>

<style lang="scss" scoped src="./UserRequirementProfile.scss"></style>
<style lang="scss" scoped>
.edit-wrapper {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}
</style>
