import CompanyRoleUserCollection from '@/models/CompanyRoleUserCollection';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import CompanyRole from '../models/CompanyRole';

export default {
    methods: {
        updateSupervisors(companyUserId) {
            return new Promise((resolve, reject) => {
                this.$http.post('updateSupervisors', {
                    supervisors: this.companyRoleUserCollection.toArray().filter((supervisor) =>  Number.isInteger(supervisor.company_user_id)),
                    enrollee_user_id: companyUserId,
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        getRoles() {
            return new Promise((resolve, reject) => {
                this.companyRoleCollection.get().then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        getSupervisors(companyUserId) {
            return new Promise((resolve, reject) => {
                this.companyRoleUserCollection.whereHas({
                    employees: (query) => {
                        query.where([
                            ['company_user_id', '=', companyUserId],
                        ]);
                    },
                });
                this.companyRoleUserCollection.get().then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        mergeEmptyRolesIntoSupervisors() {
            const existingSupervisorRoleIds = this.companyRoleUserCollection.$.models.map((companyRoleUser) => companyRoleUser.company_role_id);

            this.companyRoleCollection.$.models.each((role) => {
                if (!existingSupervisorRoleIds.includes(role.id)) {
                    const item = this.companyRoleUserCollection.new();
                    item.company_role_id = role.id;
                    item.role = role.toObject();
                    item.company_user_id = null;
                    this.companyRoleUserCollection.add(item);
                }
            });
        },
    },

    data() {
        return {
            companyRoleCollection: new CompanyRoleCollection([
                'id', 'name', 'is_heyteam', 'alias', 'is_guest',
            ])
                .where([
                    ['name', '!=', CompanyRole.NAME_EMPLOYEE],
                    ['name', '=', null, 'or'],
                ]).where([
                    ['is_assignable_program', '=', true],
                ]),
            companyRoleUserCollection: new CompanyRoleUserCollection([
                'id',
                'company_user_id',
                'company_role_id',
            ]).with({
                user: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
                role: (query) => {
                    query.select(['id', 'name', 'alias', 'is_heyteam', 'is_guest']);
                },
            }),
        };
    },
};
