<template>
    <ResourceListing
        :title="title"
        :items="formattedDocuments"
        :columns="columns"
        permission="ModelCompanySharedDocument"
        empty-message="There are no documents to display yet..."
        has-quick-actions
        @on-select-filter="fetchData"
        @on-close-modalable="fetchData"
        @on-action-clicked="onActionClicked"
    >
        <template #modalable="{ selectedResourceId }">
            <SharedDocumentEdit
                :id="selectedResourceId"
                ref="documentEdit"
                :disabled-program-list="false"
            />
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import SharedDocumentEdit from '@/components/resources/sharedDocument/SharedDocumentEdit.vue';

export default {
    name: 'Documents',
    permissions: [
        'ModelCompanySharedDocument',
    ],

    components: {
        ResourceListing,
        SharedDocumentEdit,
    },

    data() {
        return {
            documents: [],
            columns: [
                {
                    label: this.translate('Type'),
                    key: 'company_file.detected_extension',
                    class: 'text-uppercase',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },

    computed: {
        title() {
            if (this.documents.length === 0) {
                return this.translate('No document');
            }

            return this.translate('{count} document | {count} documents', {
                count: this.documents.length,
            });
        },
        formattedDocuments() {
            return this.documents.map((document) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanySharedDocument', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...document,
                    quick_actions: quickActions,
                };
            });
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        replicateDocument(document) {
            api.configuration.documents.replicate(document.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: document.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: document.resource.name,
                    }));
                });
        },
        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateDocument(item);
                break;
            }
        },
        fetchData() {
            this.$store.dispatch('resources/setLoading', true);

            api.configuration
                .documents
                .getAll({
                    filters: this.$store.state.resources.filters,
                })
                .then((res) => {
                    this.documents = res.data.data;

                    this.$store.dispatch('resources/setLoading', false);
                });
        },
    },
};
</script>
