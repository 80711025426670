<template>
    <ResourceListing
        :title="title"
        :items="requirementCategories"
        :columns="columns"
        permission="ModelCompanyRequirementCategory"
        empty-message="There are no requirements to display yet..."
        :is-modalable="false"
        @on-create="openForm"
        @on-row-clicked="onRowClicked"
        @on-select-filter="fetchData"
    >
        <template #cell(program_name)="scope">
            <span v-if="'display_survey' in scope.item && scope.item.display_survey">
                <t>Link to a survey</t>
            </span>
            <span v-else>{{ scope.item.resource.programs[0]?.program?.name ?? '-' }}</span>
        </template>

        <template #cell(nb_requirement)="scope">
            {{ scope.item.company_requirement.length }}
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';

export default {
    permissions: [
        'ModelCompanyRequirementCategory',
    ],

    components: {
        ResourceListing,
    },

    data() {
        return {
            requirementCategories: [],
            columns: [
                {
                    label: this.translate('Number of requirements'),
                    key: 'nb_requirement',
                    sortable: false,
                },
            ],
        };
    },

    computed: {
        title() {
            if (this.requirementCategories.length === 0) {
                return this.translate('No requirements');
            }

            const requirementCount = this.requirementCategories.reduce((accum, requirement) => {
                accum += requirement.company_requirement.length;
                return accum;
            }, 0);

            return `${this.translate('{count} requirement in | {count} requirements in', {
                count: requirementCount,
            })} ${this.translate('{count} category | {count} categories', {
                count: this.requirementCategories.length,
            })}`;
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        openForm() {
            this.$router.push({
                name: 'ResourcesRequirementsForm',
            });
        },

        onRowClicked(row) {
            this.$router.push({
                name: 'ResourcesRequirementsForm',
                params: {
                    id: row.item.id,
                },
            });
        },

        fetchData() {
            this.$store.dispatch('resources/setLoading', true);

            api.configuration
                .requirementCategory
                .getAll({
                    filters: this.$store.state.resources.filters,
                })
                .then((res) => {
                    this.requirementCategories = res.data.data;
                    this.$store.dispatch('resources/setLoading', false);
                });
        },
    },
};
</script>
