<template>
    <ResourceListing
        :title="title"
        :items="formattedActions"
        permission="ModelCompanyProgramTask"
        empty-message="There are no actions to display yet..."
        has-quick-actions
        has-checkboxes
        @on-select-filter="fetchData"
        @on-close-modalable="fetchData"
        @on-action-clicked="onActionClicked"
        @on-export="onExport"
        @on-import="onImport"
    >
        <template #modalable="{ selectedResourceId }">
            <TaskEdit
                :id="selectedResourceId"
                :disabled-program-list="false"
                @onClose="fetchData"
                @onSave="fetchData"
                @onDelete="fetchData"
            />
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import TaskEdit from '@/components/resources/task/TaskEdit.vue';

export default {
    components: {
        ResourceListing,
        TaskEdit,
    },

    data() {
        return {
            actions: [],
        };
    },

    computed: {
        formattedActions() {
            return this.actions.map((action) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanyProgramTask', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...action,
                    quick_actions: quickActions,
                };
            });
        },
        title() {
            if (this.actions.length === 0) {
                return this.translate('No action');
            }

            return this.translate('{count} action | {count} actions', {
                count: this.actions.length,
            });
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        replicateAction(action) {
            api.configuration.actions.replicate(action.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: action.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: action.resource.name,
                    }));
                });
        },
        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateAction(item);
                break;
            }
        },
        fetchData() {
            this.$store.dispatch('resources/setLoading', true);

            api.configuration
                .actions
                .getAll({
                    filters: this.$store.state.resources.filters,
                })
                .then((res) => {
                    this.actions = res.data.data;

                    this.$store.dispatch('resources/setLoading', false);
                });
        },
        onExport(items) {
            api.configuration.actions.createExport({
                ids: items.map((item) => item.id),
            }).then(() => {
                this.$Notifier('App').showInfo(this.translate("Action export request sent! You'll get a notification once it's done!"));
            }).catch(() => {
                this.$Notifier('App').showError(this.translate('Unable to initial export'));
            });
        },
        onImport(jsonContent) {
            api.configuration.actions.import(jsonContent).then(() => {
                this.$Notifier('App').showInfo(this.translate("Action import request sent! You'll get a notification once it's done!"));
            }).catch(() => {
                this.$Notifier('App').showError(this.translate('Unable to initial export'));
            });
        },
    },
};
</script>
