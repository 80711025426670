import Vue from 'vue';

/**
 * @typedef {Object} QuizAnswer
 * @property {number} id
 * @property {boolean} is_correct_answer
 * @property {string} text
 * @property {{text: Object<string, string>}} translations
 * @property {Object<string, FileTranslation|null>} file_translations
 */

/**
 * @typedef {Object} QuizQuestion
 * @property {number} id
 * @property {string} text
 * @property {{text: Object<string, string>}} translations
 * @property {QuizAnswer[]} answers
 * @property {Object<string, FileTranslation|null>} file_translations
 */

/**
 * @typedef {Object} Quiz
 * @property {number} id
 * @property {{message_welcome: Object<string, string>, message_congratulary: Object<string, string>}} translations
 * @property {Resource} resource
 * @property {QuizQuestion[]} questions
 */

/**
 * @typedef {{data: {data: Quiz}}} QuizResourceResponse
 * @typedef {{data: {data: Quiz[]}}} QuizCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<QuizCollectionResponse>}
     */
    list(data = {}) {
        return Vue.prototype.$http.post('configuration/quizzes/list', data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<QuizResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/quizzes/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<QuizResourceResponse>}
     */
    store(data = {}) {
        return Vue.prototype.$http.post('configuration/quizzes', data);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<QuizResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/quizzes/${id}/replicate`, config);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<QuizResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.put(`configuration/quizzes/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`configuration/quizzes/${id}`, config);
    },
};
