<template>
    <div class="user-requirement-edit">
        <component
            :is="getComponent(currentRequirement.type)"
            :is-participant="isParticipant"
            :requirement="currentRequirement"
            :participants="participants"
            :mode-edit="modeEdit"
            :index="index"
            :is-disabled="isDisabled"
            :signer-task="signerTask"
            :requirement-has-changed="requirementHasChanged"
            :user-id="userId"
            :value="{html_content: currentRequirement.value}"
            :is-preview="true"
            :can-update="canUpdate"
            @onChangeValue="onChangeValue(...arguments)"
            @on-update-requirement="onUpdateRequirement(...arguments)"
        />
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import DynamicDocument from '@/components/globals/DynamicDocument.vue';
import UserRequirementTextWithControlEdit from '@/components/pages/onboarding/type/UserRequirementTextWithControlEdit.vue';
import UserRequirementSocialSecurityNumberEdit from '@/components/pages/onboarding/type/UserRequirementSocialSecurityNumberEdit.vue';
import UserRequirementTextEdit from './type/UserRequirementTextEdit.vue';
import UserRequirementFileToSignEdit from './type/UserRequirementFileToSignEdit.vue';
import UserRequirementStringEdit from './type/UserRequirementStringEdit.vue';
import UserRequirementFileToFillEdit from './type/fileToFill/UserRequirementFileToFillEdit.vue';
import UserRequirementDateEdit from './type/UserRequirementDateEdit.vue';
import UserRequirementAddressEdit from './type/UserRequirementAddressEdit.vue';
import UserRequirementSingleSelectEdit from './type/UserRequirementSingleSelectEdit.vue';
import UserRequirementMultipleSelectEdit from './type/UserRequirementMultipleSelectEdit.vue';
import UserRequirementIbanEdit from './type/UserRequirementIbanEdit.vue';
import UserRequirementFileEdit from './type/file/UserRequirementFileEdit.vue';
import UserRequirementHexapostEdit from './type/UserRequirementHexapostEdit.vue';

export default {
    name: 'UserRequirementEdit',
    components: {
        UserRequirementTextEdit,
        UserRequirementSocialSecurityNumberEdit,
        UserRequirementTextWithControlEdit,
        UserRequirementFileToSignEdit,
        UserRequirementFileToFillEdit,
        UserRequirementStringEdit,
        UserRequirementDateEdit,
        UserRequirementAddressEdit,
        UserRequirementSingleSelectEdit,
        UserRequirementMultipleSelectEdit,
        UserRequirementIbanEdit,
        UserRequirementFileEdit,
        UserRequirementHexapostEdit,
        DynamicDocument,
    },
    inject: ['$validator'],

    props: {
        requirement: {
            type: Object,
            required: true,
        },
        participants: {
            type: Array,
            default: () => [],
        },
        isParticipant: {
            type: Boolean,
            default: false,
        },
        modeEdit: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        signerTask: {
            type: CompanyUserProgramTask,
            default: null,
        },
        userId: {
            type: Number,
            required: true,
        },
        canUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            currentRequirement: null,
            requirementHasChanged: false,
        };
    },

    created() {
        this.currentRequirement = this.requirement;
    },

    methods: {
        onChangeValue(id, hasChanged, isComplete = true) {
            this.$emit('onChangeValue', id, hasChanged, isComplete);
        },

        onUpdateRequirement(requirement) {
            this.currentRequirement = requirement;
            this.requirementHasChanged = true;
            this.$emit('on-update-requirement', requirement);
        },

        getComponent(userRequirementCategoryType) {
            switch (userRequirementCategoryType) {
            case 'text':
            case 'phone':
                return UserRequirementTextEdit;
            case 'string':
                return UserRequirementStringEdit;
            case 'social security number':
                return UserRequirementSocialSecurityNumberEdit;
            case 'text_with_control':
                return UserRequirementTextWithControlEdit;
            case 'file':
                return UserRequirementFileEdit;
            case 'date':
                return UserRequirementDateEdit;
            case 'iban':
                return UserRequirementIbanEdit;
            case 'address':
                return UserRequirementAddressEdit;
            case 'single_select':
                return UserRequirementSingleSelectEdit;
            case 'multiple_select':
                return UserRequirementMultipleSelectEdit;
            case 'file_to_sign':
                return UserRequirementFileToSignEdit;
            case 'file_to_fill':
                return UserRequirementFileToFillEdit;
            case 'hexapost':
                return UserRequirementHexapostEdit;
            case 'dynamic_document':
                return DynamicDocument;
            default:
                return null;
            }
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementEdit.scss" scoped />
