import moment from 'moment';
import Model from '@tony.caron/node-model-proxy/Model';
import Notifier from '../Notifier';
import I18n from '../modules/i18n/I18n';
import CompanyFile from './CompanyFile';
import CompanyLanguage from './CompanyLanguage';
import CompanyContract from './CompanyContract';
import CompanyOffice from './CompanyOffice';
import CompanyDepartment from './CompanyDepartment';
import CompanyJobPosition from './CompanyJobPosition';
import CompanyUserMetadata from './CompanyUserMetadata';
import CompanyUserProgram from './CompanyUserProgram';
import CompanyPlanningEvent from './CompanyPlanningEvent';
import CompanyUserEmailCustom from './CompanyUserEmailCustom';
import CompanyUserCollection from './CompanyUserCollection';
import CompanyUserSoftware from './CompanyUserSoftware';
import CompanyUserEquipment from './CompanyUserEquipment';
import Company from './Company';
import CompanyField from './CompanyField';
import CompanyRoleUser from './CompanyRoleUser';
import CompanyUserEntity from './CompanyUserEntity';
import CompanyRoleUserEmployee from './CompanyRoleUserEmployee';

export default class CompanyUser extends Model {
    static STATUS_DRAFT = 'draft';

    static STATUS_ACTIVE = 'active';

    static STATUS_INVITED = 'invited';

    static STATUS_CANCELLED = 'cancelled';

    static STATUS_INACTIVE = 'inactive';

    static ROLE_SUPERADMIN = 'superadmin';

    static STATE_PREBOARDING = 'preboarding';

    modelConfig() {
        return {
            controller: 'CompanyUserController',
            collection: CompanyUserCollection,
        };
    }

    modelCustomAttributes() {
        return {
            newPassword: null,
            isConnected: false,
            probationFinalEndDate: null,
            company_program_id: null,
            is_guest: null,
        };
    }

    modelAccessors() {
        return {
            probationFinalEndDate: () => {
                const endDate = moment(this.arrival_date).add(this.first_probation, this.first_probation_unit);

                if (this.second_probation_end_date !== null) {
                    endDate.add(this.second_probation, this.second_probation_unit);
                }

                return endDate.format('YYYY-MM-DD HH:mm:ss');
            },
        };
    }

    modelEvents() {
        return {
            failed(code, data) {
                if ('errors' in data && data.errors.hasOwnProperty('phone_number')) {
                    Notifier.getInstance('App').showError(
                        I18n.translate(data.errors.phone_number[0]),
                    );
                } else if ('message' in data) {
                    Notifier.getInstance('App').showError(
                        I18n.translate(data.message),
                    );
                } else {
                    Notifier.getInstance('App').showError(
                        I18n.translate('A problem occurred while trying to update this user'),
                    );
                }
            },
        };
    }

    modelInConvertor() {
        return {
            is_ghost: (n) => Boolean(n),
        };
    }

    modelRelations() {
        return {
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyContract: () => this.belongsTo(CompanyContract, 'company_contract_id', 'id'),
            companyLanguage: () => this.belongsTo(CompanyLanguage, 'company_language_id', 'id'),
            company: () => this.belongsTo(Company, 'company_id'),
            companyOffice: () => this.belongsTo(CompanyOffice, 'company_office_id', 'id'),
            companyDepartment: () => this.belongsTo(CompanyDepartment, 'company_department_id', 'id'),
            companyJobPosition: () => this.belongsTo(CompanyJobPosition, 'company_job_position_id', 'id'),
            roleUser: () => this.hasMany(CompanyRoleUser),
            companyRoleUser: () => this.hasMany(CompanyRoleUser),
            companyUserHobbies: () => this.hasMany(CompanyUserMetadata),
            companyUserSkills: () => this.hasMany(CompanyUserMetadata),
            companyUserMetadata: () => this.hasMany(CompanyUserMetadata),
            companyUserProgram: () => this.hasMany(CompanyUserProgram, 'company_user_id', 'id'),
            programs: () => this.hasMany(CompanyUserProgram, 'company_user_id', 'id'),
            companyPlanningEvents: () => this.hasMany(CompanyPlanningEvent, 'company_user_id', 'id'),
            companyUserEmailCustom: () => this.hasMany(CompanyUserEmailCustom, 'company_user_id', 'id'),
            companyUserSoftware: () => this.hasMany(CompanyUserSoftware, 'company_user_id', 'id'),
            companyUserEquipment: () => this.hasMany(CompanyUserEquipment, 'company_user_id', 'id'),
            defaultEntities: () => this.hasMany(CompanyUserEntity, 'company_user_id', 'id'),
            entities: () => this.hasMany(CompanyUserEntity, 'company_user_id', 'id'),
            customFields: () => this.hasMany(CompanyField),
            companyRoleUserEmployee: () => this.hasMany(CompanyRoleUserEmployee),
        };
    }

    isAdmin() {
        return this.company_role_user.models.some((roleUser) => roleUser.role.name === 'admin');
    }

    isContainingValidSsoDomain(email, expectedDomains) {
        if (!expectedDomains || !expectedDomains.length) {
            return true;
        }

        return expectedDomains.some((domain) => {
            const domainPart = email.split('@')[1];
            return domainPart.includes(domain);
        });
    }
}
