<template>
    <ht-form-group
        :label="translate('Phone number')"
        :is-required="isRequired"
        :show-asterisk="isRequired"
        class="mb-0"
    >
        <div class="d-flex">
            <HtFormMultiSelect
                :id="'phoneFlag'"
                v-model="companyUser.phone_country_code"
                :options="countryCodeList"
                :name="'phoneFlag'"
                :track-by="'name'"
                :is-single="true"
                :specific-key="'dial_code'"
                class="d-flex mr-2"
                style="flex:1;"
            >
                <template
                    slot="singleLabel"
                    slot-scope="{ option }"
                >
                    <div
                        class="d-flex align-items-center"
                        style="width:100%"
                    >
                        <country-flag
                            :country="option.code"
                            size="small"
                        />
                        <span class="ml-10 single-ellipsis">{{ option.dial_code }}</span>
                    </div>
                </template>
                <template
                    slot="option"
                    slot-scope="{ option }"
                >
                    <div class="d-flex align-items-center">
                        <country-flag
                            :country="option.code"
                            size="small"
                        />
                        <span class="ml-2">{{ option.dial_code }} {{ translate(option.name) }}</span>
                    </div>
                </template>
            </HtFormMultiSelect>

            <HtFormInput
                :id="'phoneNumber'"
                v-model="companyUser.phone_number"
                v-validate.disable="phoneValidation"
                :name="'phone_number'"
                :placeholder="'6********'"
                :data-vv-as="translate('phone number')"
                cypress="phone-number"
                :error-append="errorAppend"
                style="flex:3;"
            />
        </div>
    </ht-form-group>
</template>

<script>
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import CountryCodeList from '@/CountryCode';

export default {
    name: 'PhoneNumberInput',

    components: {
        HtFormMultiSelect,
        HtFormInput,
    },

    inject: ['$validator'],

    props: {
        companyUser: {
            type: [Object],
            required: true,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        errorAppend: {
            type: String,
            default: () => null,
        },
    },

    data() {
        return {
            countryCodeList: CountryCodeList.sort((a, b) => a.name > b.name),
        };
    },

    computed: {
        phoneValidation() {
            const validation = `numeric|unique_phone:${this.companyUser.phone_country_code},${this.companyUser.id}`;

            if (this.isRequired) {
                return `required|${validation}`;
            }

            return validation;
        },
    },

};
</script>

<style scoped>

</style>
